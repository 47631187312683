<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card :loading="loading">
        <v-card-text class="text-center">
           <img
            v-if="$vuetify.theme.dark"
            class="mb-4"
            height="50"
            src="@/assets/images/logo/logo-light.png"
            alt=""
          />
          <img
            v-else
            class="mb-4"
            height="50"
            src="@/assets/images/logo/logo-dark.png"
            alt=""
          />
          <template v-if="!loading && !error">
            <h5>Vamos redefinir sua senha</h5>
            <h6 class="text--disabled font-weight-medium mb-10">
              Preencha as senha para continuar
            </h6>

            <v-form v-model="valid" @submit.prevent="processRecaptcha">
              <v-text-field label="WhatsApp" disabled v-model="user.phone" />
              <v-text-field
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                name="input-10-2"
                label="Senha"
                v-model="user.password"
                :disabled="innerLoading"
                :rules="passwordRules"
                @click:append="show = !show"
              ></v-text-field>
              <v-text-field
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                name="input-10-2"
                label="Confirme a senha"
                v-model="confirmPassword"
                :rules="[
                  (v) => !!v || 'Confirmação da senha é obrigatória',
                  (v) => v === user.password || 'Senhas não conferem',
                ]"
                :disabled="innerLoading"
                @click:append="show = !show"
              ></v-text-field>
              <v-alert
                v-if="innerError"
                border="left"
                class="text-left"
                text
                icon="mdi-alert-circle-outline"
                type="error"
              >
                {{ innerError }}
              </v-alert>
              <v-btn
                class="mb-4"
                block
                color="primary"
                :loading="innerLoading"
                :disabled="!valid"
                type="submit"
              >
                Redefinir Senha
              </v-btn>
            </v-form>
          </template>
          <template v-else-if="!error">
            <v-alert
              border="left"
              class="text-left"
              text
              icon="mdi-information-outline"
              type="info"
            >
              Aguarde enquanto o nosso sistema verifica seu WhatsApp.
            </v-alert>
          </template>
          <template v-else>
            <v-alert
              border="left"
              class="text-left"
              text
              icon="mdi-alert-circle-outline"
              type="error"
            >
              {{ error }}
            </v-alert>
            <v-btn
              v-if="error"
              color="secondary"
              class="mt-4"
              block
              :to="{
                name: 'sign-in',
              }"
            >
              Voltar
            </v-btn>
          </template>
        </v-card-text>
      </base-card>
    </div>
    <re-captcha
      ref="recaptcha"
      autoRun
      @success="execute"
      @error="recaptchaError"
      @close="recaptchaError"
    />
  </div>
</template>
<script>
import ReCaptcha from "@/components/global/ReCaptcha";
import AUTH from "@/services/auth";
import { mapActions } from "vuex";
import passwordRules from "@/utils/password-rules";

export default {
  name: "ResetPassword",
  metaInfo: {
    title: "Criar Conta",
  },
  data: () => ({
    show: false,
    loading: true,
    error: false,
    innerLoading: false,
    innerError: false,
    valid: false,
    user: {
      phone: "(14) 99999-9999",
      password: "",
    },
    confirmPassword: "",
    passwordRules: passwordRules(),
  }),
  methods: {
    ...mapActions("auth", ["setLogin"]),
    processRecaptcha() {
      this.innerLoading = true;
      this.innerError = false;
      this.$refs.recaptcha.execute();
    },
    execute(token) {
      if (this.f) {
        this.f(token);
      }
    },
    async register(recaptcha) {
      try {
        this.innerLoading = true;
        this.innerError = false;

        let token = this.$route.query.token;
        const response = await AUTH.resetPassword.useToken(
          token,
          this.user.password,
          recaptcha
        );
        await this.setLogin(response);
        this.$router.push("/app");
      } catch (e) {
        this.innerError = e.message;
      } finally {
        this.innerLoading = false;
      }
    },
    async readToken(recaptcha) {
      try {
        let token = this.$route.query.token;
        let response = await AUTH.resetPassword.readToken(token, recaptcha);
        this.user.phone = response.user.phone;
        this.loading = false;
        this.innerLoading = false;
        this.f = this.register;
      } catch (e) {
        this.loading = false;
        this.error = e.message;
      }
    },
    recaptchaError() {
      if (this.loading) {
        this.loading = false;
        this.error = "Erro ao validar o captcha, atualize a página e tente novamente.";
      } else {
        this.innerLoading = false;
        this.innerError = "Erro ao validar o captcha";
      }
    },
    formatName(name) {
      return name
        .toLowerCase()
        .split(" ")
        .map((n) => (n.length > 3 ? n.charAt(0).toUpperCase() + n.slice(1) : n))
        .join(" ");
    },
  },
  mounted() {
    let token = this.$route.query.token;
    if (!token) {
      this.loading = false;
      this.error =
        "Você precisa acessar essa página a partir do link enviado para seu WhatsApp. Não recebeu o link? Clique em voltar e tente novamente.";
    } else {
      this.f = this.readToken;
    }
  },
  components: {
    ReCaptcha,
  },
};
</script>
<style lang="scss" scoped>
.page-wrap {
  background: radial-gradient(circle at 50% 50%, rgba(0,0,0,50%) 0%, rgba(0,0,0,90%) 90vw),
    url(/img/logo/auth-background.png), black;
  display: flex;
  background-size: cover;
  background-position: center;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
</style>
